// src/App.js
import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import MapSection from './components/MapSection';
import Services from './components/Services';
import QuestionsSection from './components/QuestionsSection';
import HelpSection from './components/HelpSection';
import WhyChooseSOSBox from './components/WhyChooseSOSBox';
import OffersAndRates from './components/OffersAndRates';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import DevisForm from './components/DevisForm';
import ReservationForm from './components/ReservationForm';
import ReservationSuccess from './components/ReservationSuccess';
import ContactForm from './components/ContactForm'; // Importer le composant du formulaire de contact
import PrivacyPolicy from './components/PrivacyPolicy'; // Importer le composant Politique de Confidentialité
import TermsOfUse from './components/TermsOfUse'; // Importer le composant Conditions d'utilisation

function App() {
  // Références pour cibler les sections à scroller
  const whyChooseRef = useRef(null);
  const offersRef = useRef(null);

  // Fonction pour faire défiler jusqu'à la section "Pourquoi choisir un SOSBox"
  const scrollToWhyChoose = () => {
    if (whyChooseRef.current) {
      whyChooseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Fonction pour faire défiler jusqu'à la section "Offres et tarifs"
  const scrollToOffers = () => {
    if (offersRef.current) {
      offersRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    
    <Router>
      
      <div>
        {/* Passer les fonctions de défilement à la Navbar */}
        <Navbar 
          scrollToWhyChoose={scrollToWhyChoose} 
          scrollToOffers={scrollToOffers} 
        />
        
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/demande-devis" element={<DevisForm />} />
          <Route path="/reserver-visite" element={<ReservationForm />} />
          <Route path="/reservation-success" element={<ReservationSuccess />} />
          <Route path="/contact" element={<ContactForm />} /> {/* Nouvelle route pour le formulaire de contact */}
          <Route path="/privacy" element={<PrivacyPolicy />} /> {/* Nouvelle route pour la politique de confidentialité */}
          <Route path="/terms" element={<TermsOfUse />} /> {/* Nouvelle route pour les conditions d'utilisation */}
        </Routes>

        {/* Les sections du site */}
        <MapSection />
        <Services />
        <QuestionsSection />
        <HelpSection />

        {/* Section Pourquoi choisir un SOSBox */}
        <div ref={whyChooseRef}>
          <WhyChooseSOSBox />
        </div>

        {/* Section Offres et tarifs */}
        <div ref={offersRef}>
          <OffersAndRates />
        </div>

        <div className="outer-container">
      {/* Ton contenu ici */}
    </div>

        {/* Autres sections */}
        <Testimonials />
        <FAQ />

        <div className="btn-group">
          <a href="/reserver-visite" className="btn">Réserver une visite</a>
          <a href="/demande-devis" className="btn">Demander un devis</a>
        </div>
        <div className="image-content1">
  <a href="https://www.national-box.com/" target="_blank" rel="noopener noreferrer">
    <img src="https://i.postimg.cc/4473bs7m/unnamed.png" alt="SOSBox" />
  </a>
</div>


        {/* Pied de page */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
